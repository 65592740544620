<template>
  <v-dialog v-model="value" persistent max-width="500">
    <v-card class="mx-auto">
      <v-toolbar color="blue" dark flat>
        <v-toolbar-title>Weather forecast</v-toolbar-title>
      </v-toolbar>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            {{ weatherLocation.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            >{{
              weatherLocationData.data
                ? epochToDate(weatherLocationData.data.current.dt)
                : ''
            }},
            {{
              weatherLocationData.data
                ? weatherLocationData.data.current.weather[0].description
                : ''
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row>
          <v-col class="text-h2" cols="6">
            {{
              weatherLocationData.data
                ? Math.round(Number(weatherLocationData.data.current.temp))
                : ''
            }}F</v-col
          >
          <v-col cols="3"></v-col>
          <v-col cols="3">
            <v-icon size="65" color="blue">mdi-white-balance-sunny</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="mt-n6"
          ><v-col
            >Feels like
            {{
              weatherLocationData.data
                ? Math.round(
                    Number(weatherLocationData.data.current.feels_like)
                  )
                : ''
            }}F</v-col
          ></v-row
        >
        <v-row
          v-if="weatherLocationData.data && weatherLocationData.data.alerts"
        >
          <v-col>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <div class="text-h6 red--text" v-on="on">
                  {{ weatherLocationData.data.alerts[0].event }}
                </div>
              </template>
              <span>{{ weatherLocationData.data.alerts[0].description }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-windsock</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          {{
            weatherLocationData.data
              ? Math.round(Number(weatherLocationData.data.current.wind_speed))
              : ''
          }}
          mph</v-list-item-subtitle
        >
        <v-list-item-subtitle>
          <div
            v-if="
              weatherLocationData.data &&
              weatherLocationData.data.current.wind_gust
            "
          >
            <v-icon>mdi-weather-windy</v-icon>
            {{
              weatherLocationData.data
                ? weatherLocationData.data.current.wind_gust
                  ? Math.round(
                      Number(weatherLocationData.data.current.wind_gust)
                    )
                  : ''
                : ''
            }}
            mph
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon>mdi-compass</v-icon>
          wind from
          {{
            weatherLocationData.data
              ? windDirection(Number(weatherLocationData.data.current.wind_deg))
              : ''
          }}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-water-percent</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>
          {{
            weatherLocationData.data
              ? weatherLocationData.data.current.humidity
              : ''
          }}%</v-list-item-subtitle
        >
        <v-list-item-subtitle>
          <v-icon>mdi-gauge</v-icon>
          {{
            weatherLocationData.data
              ? Math.round(Number(weatherLocationData.data.current.pressure))
              : ''
          }}
          hPa</v-list-item-subtitle
        >
        <v-list-item-subtitle>
          <v-icon>mdi-sun-wireless</v-icon>
          {{
            weatherLocationData.data
              ? Math.round(Number(weatherLocationData.data.current.uvi))
              : ''
          }}
          UV index</v-list-item-subtitle
        >
      </v-list-item>
      <v-divider />
      <v-list class="transparent">
        <v-list-item v-for="item in weatherForecast" :key="item.day">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item-title v-on="on">{{ item.day }}</v-list-item-title>
            </template>
            <span
              >Sunrise @ {{ item.sunrise }} / Sunset @ {{ item.sunset }}</span
            >
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon color="blue">{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>

          <v-list-item-subtitle class="text-right">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  :color="
                    item.feelslikeLow < 40 || item.feelslikeHigh > 100
                      ? 'red'
                      : 'grey darken-1'
                  "
                  small
                  v-on="on"
                  >mdi-thermometer</v-icon
                >
              </template>
              <span
                >Feels like {{ item.feelslikeLow }} /
                {{ item.feelslikeHigh }} F</span
              >
            </v-tooltip>

            {{ item.tempLow }} / {{ item.tempHigh }}F
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-right">
            <v-icon small>mdi-water-percent</v-icon>{{ item.humidity }}%
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-right">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  small
                  :color="
                    item.wind > 30 || item.windgust > 30
                      ? 'red'
                      : 'grey darken-1'
                  "
                  v-on="on"
                  >mdi-windsock</v-icon
                >{{ item.wind }} mph
              </template>
              <span>Wind gusts up to {{ item.windgust }} mph</span>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="cancelDialog">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
export default {
  name: 'DialogWeather',
  props: {
    value: { required: true, type: Boolean },
    weatherLocation: { required: true, type: Object }
  },
  data() {
    return {
      weatherLocationData: {},
      labels: ['SU', 'MO', 'TU', 'WED', 'TH', 'FR', 'SA'],
      time: 0,
      forecast: [
        {
          day: 'Tuesday',
          icon: 'mdi-white-balance-sunny',
          temp: '24\xB0/12\xB0'
        },
        {
          day: 'Wednesday',
          icon: 'mdi-white-balance-sunny',
          temp: '22\xB0/14\xB0'
        },
        { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' }
      ]
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    }),
    ...mapGetters('moduleBonusLocations', ['BonusLocationsByNameGetter']),
    weatherForecast() {
      const iconMap = new Map()
      iconMap.set('01d', 'mdi-white-balance-sunny')
      iconMap.set('01n', 'mdi-white-balance-sunny')
      iconMap.set('02d', 'mdi-weather-partly-cloudy')
      iconMap.set('02n', 'mdi-weather-partly-cloudy')
      iconMap.set('03d', 'mdi-weather-cloudy')
      iconMap.set('03n', 'mdi-weather-cloudy')
      iconMap.set('04d', 'mdi-weather-partly-rainy')
      iconMap.set('04n', 'mdi-weather-partly-rainy')
      iconMap.set('09d', 'mdi-weather-rainy')
      iconMap.set('09n', 'mdi-weather-rainy')
      iconMap.set('10d', 'mdi-weather-pouring')
      iconMap.set('11d', 'mdi-weather-lightning')
      iconMap.set('11n', 'mdi-weather-lightning')
      iconMap.set('12d', 'mdi-weather-snowy')
      iconMap.set('12n', 'mdi-weather-snowy')
      iconMap.set('13d', 'mdi-weather-weather-fog')
      iconMap.set('13n', 'mdi-weather-weather-fog')
      if (Object.keys(this.weatherLocationData).length === 0) return
      let forecast = []
      for (let index = 0; index <= 6; index++) {
        let dayforecast = {
          day: DateTime.fromSeconds(
            Number(this.weatherLocationData.data.daily[index].dt)
          ).toFormat('cccc'),
          tempHigh: Math.round(
            Number(this.weatherLocationData.data.daily[index].temp.max)
          ),
          tempLow: Math.round(
            Number(this.weatherLocationData.data.daily[index].temp.min)
          ),
          feelslikeHigh: Math.round(
            Number(this.weatherLocationData.data.daily[index].feels_like.day)
          ),
          feelslikeLow: Math.round(
            Number(this.weatherLocationData.data.daily[index].feels_like.morn)
          ),
          wind: Math.round(
            Number(this.weatherLocationData.data.daily[index].wind_speed)
          ),
          windgust: Math.round(
            Number(this.weatherLocationData.data.daily[index].wind_gust)
          ),
          humidity: Number(this.weatherLocationData.data.daily[index].humidity),
          icon: iconMap.get(
            this.weatherLocationData.data.daily[index].weather[0].icon
          ),
          description:
            this.weatherLocationData.data.daily[index].weather[0].description,
          sunset: DateTime.fromSeconds(
            Number(this.weatherLocationData.data.daily[index].sunset)
          ).toFormat('t'),
          sunrise: DateTime.fromSeconds(
            Number(this.weatherLocationData.data.daily[index].sunrise)
          ).toFormat('t')
        }
        forecast.push(dayforecast)
      }
      return forecast
    }
  },
  watch: {
    weatherLocation() {
      this.getWeatherData()
    }
  },
  methods: {
    cancelDialog() {
      this.$emit('input', false)
    },
    windDirection(_degrees) {
      if (_degrees >= 348.75 || _degrees < 11.25) {
        return 'N'
      } else if (_degrees >= 11.25 && _degrees < 33.75) {
        return 'NNE'
      } else if (_degrees >= 33.75 && _degrees < 56.25) {
        return 'NE'
      } else if (_degrees >= 56.25 && _degrees < 78.75) {
        return 'ENE'
      } else if (_degrees >= 78.25 && _degrees < 101.25) {
        return 'E'
      } else if (_degrees >= 101.25 && _degrees < 123.75) {
        return 'ESE'
      } else if (_degrees >= 123.75 && _degrees < 146.25) {
        return 'SE'
      } else if (_degrees >= 146.25 && _degrees < 168.75) {
        return 'SSE'
      } else if (_degrees >= 168.75 && _degrees < 191.25) {
        return 'S'
      } else if (_degrees >= 191.25 && _degrees < 213.75) {
        return 'SSW'
      } else if (_degrees >= 213.75 && _degrees < 236.25) {
        return 'SW'
      } else if (_degrees >= 236.25 && _degrees < 258.75) {
        return 'WSW'
      } else if (_degrees >= 258.75 && _degrees < 281.25) {
        return 'W'
      } else if (_degrees >= 281.25 && _degrees < 303.75) {
        return 'WNW'
      } else if (_degrees >= 303.75 && _degrees < 326.25) {
        return 'NW'
      } else if (_degrees >= 326.25 && _degrees < 348.75) {
        return 'NNW'
      }
    },
    epochToDate(_epoch) {
      return DateTime.fromSeconds(_epoch).toFormat('ccc, t')
    },
    async getWeatherData() {
      if (Object.keys(this.weatherLocation).length === 0) return
      const axios = require('axios')
      this.weatherLocationData = {}
      let axiosQuery = `https://api.openweathermap.org/data/2.5/onecall?lat=${
        this.BonusLocationsByNameGetter(this.weatherLocation.name).latitude
      }&lon=${
        this.BonusLocationsByNameGetter(this.weatherLocation.name).longitude
      }&units=imperial&exclude=minutely,hourly&appid=${
        this.Preferences.secrets.OWM_API_KEY
      }`
      let axiosResult = await axios.get(axiosQuery)
      this.weatherLocationData = JSON.parse(JSON.stringify(axiosResult))
    }
  }
}
</script>

<style></style>
