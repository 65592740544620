import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"1000px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Select route")])],1),_c(VCardText,{staticClass:"mt-6 body-1"},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.RoutesSortedByTimeGetter,"sort-by":['description'],"dense":""},on:{"click:row":_vm.loadRoute},scopedSlots:_vm._u([{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getFormattedtime())+" ")]}},{key:"item.selected",fn:function(ref){
var item = ref.item;
return [(item.routeid === _vm.RoutesGetMostRecentRouteGetter().routeid)?_c(VIcon,[_vm._v("mdi-arrow-right-thick")]):_vm._e()]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Dismiss")]),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }