import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VOverlay,{attrs:{"value":_vm.showLoadingOverlay}},[_c('div',[_vm._v("Please wait while we recalculate the route...")]),_c('div',{staticClass:"text-center mt-10"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)]),_c(VOverlay,{attrs:{"value":_vm.showApiThrottleOverlay}},[_c('div',[_vm._v(" We have to slow you down and wait a little to stay within the rate limits of the Open Route Service API to avoid throttling... ")]),_c('div',{staticClass:"text-center mt-10"},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)]),_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Route planning")]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.TEST()}}},[_c(VIcon,_vm._g({attrs:{"color":"yellow"}},on),[_vm._v("mdi-nuke")])],1)]}}])},[_c('span',[_vm._v("Test something")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.shortestRoute()}}},[_c(VIcon,_vm._g({attrs:{"dark":""}},on),[_vm._v("mdi-map-marker-check")])],1)]}}])},[_c('span',[_vm._v("Optimize route")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.createNewRoute()}}},[_c(VIcon,_vm._g({attrs:{"dark":""}},on),[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Create new route")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.askDeleteRoute()}}},[_c(VIcon,_vm._g({attrs:{"dark":""}},on),[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Delete route")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){_vm.showSelectRouteDialog = true}}},[_c(VIcon,_vm._g({attrs:{"dark":""}},on),[_vm._v("mdi-view-list")])],1)]}}])},[_c('span',[_vm._v("Load route")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.calculateSegment(2, true)}}},[_c(VIcon,_vm._g({attrs:{"dark":""}},on),[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh cache and recalculate")])])],1),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{ref:"autoCompleteRiding",attrs:{"tabindex":"1","items":_vm.filteredBonusLocationsRidingOnly,"item-value":"name","color":"blue darken-4","dense":"","auto-select-first":"","item-text":"name","label":"Stop","rules":[function (v) { return !!v || 'Item is required'; }]},on:{"change":function($event){return _vm.addRouteStop(_vm.bonusStopAutoSelect)}},model:{value:(_vm.bonusStopAutoSelect),callback:function ($$v) {_vm.bonusStopAutoSelect=$$v},expression:"bonusStopAutoSelect"}})],1)],1)],1),(_vm.draggableRouteStops)?_c(VSimpleTable,{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Points")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Distance "),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.toggleSwitchCumulativeDistance =
                        !_vm.toggleSwitchCumulativeDistance}}},on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-sync")])],1)]}}],null,false,3727746724)},[_c('span',[_vm._v("Toggle between distance and cumulative distance")])])],1),_c('th',{attrs:{"scope":"col"}},[_vm._v("Stops")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Moving time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Stopped time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Arrivaltime "),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.toggleSwitchDateDay === 'date'
                        ? (_vm.toggleSwitchDateDay = 'day')
                        : (_vm.toggleSwitchDateDay = 'date')}}},on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-sync")])],1)]}}],null,false,293406302)},[_c('span',[_vm._v("Toggle between date and day")])])],1),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tfoot',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.RouteStopsTotalStops)+" stops")]),_c('th',[_vm._v(_vm._s(_vm.RouteStopsTotalPoints.toLocaleString())+" points")]),_c('th',[_vm._v(" "+_vm._s(Math.round(_vm.RouteStopsTotalDistance).toLocaleString())+" miles ")]),_c('th',[_vm._v(" "+_vm._s(Number(_vm.RouteStopsTotalFuelStops) + Number(_vm.RouteStopsTotalRestStops))+" stops ")]),_c('th',[_vm._v(" "+_vm._s(_vm.formatTravelTime(Number(_vm.RouteStopsTotalTime)))+" ")]),_c('th',[_vm._v(_vm._s(_vm.formatTravelTime(_vm.RouteStopsTotalStoppedTime)))]),_c('th',[_vm._v(" "+_vm._s(_vm.formatTravelTime( Number(_vm.RouteStopsTotalTime) + Number(_vm.RouteStopsTotalStoppedTime) ))+" ")]),_c('th')])]),_c('draggable',{attrs:{"tag":"tbody","move":_vm.checkMove},model:{value:(_vm.draggableRouteStops),callback:function ($$v) {_vm.draggableRouteStops=$$v},expression:"draggableRouteStops"}},_vm._l((_vm.draggableRouteStops),function(item){return _c('tr',{key:item.name,on:{"click":function($event){return _vm.displayRouteStopDetails(item)}}},[_c('td',{attrs:{"scope":"row"}},[(item.name)?_c(VIcon,{attrs:{"small":"","color":item.getSymbolcolor()}},[_vm._v(_vm._s(item.getMaterialicon()))]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(" "+_vm._s(( Number(item.points) + Number(_vm.RouteStopsRestPointsByName(item.name)) ).toLocaleString())+" "),(
                  _vm.BonusLocationsCombosConstituentsGetter.filter(
                    function (bonuslocation) { return bonuslocation.name === item.name; }
                  ).length
                )?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-alpha-c")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.getComboMemberships())}})]):_vm._e(),(_vm.BonusLocationsByNameGetter(item.name).proximity)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-leak")])]}}],null,true)},[_c('span',[_vm._v("This bonus is in close proximity to another bonus ("+_vm._s(_vm.BonusLocationsByNameGetter(item.name) .proximity.replace(item.name, '') .replace(',', ''))+")")])]):_vm._e()],1),_c('td',[(!_vm.toggleSwitchCumulativeDistance)?_c('div',[_vm._v(" "+_vm._s(Math.round(Number(item.map.distance)).toLocaleString())+" ")]):_vm._e(),(_vm.toggleSwitchCumulativeDistance)?_c('div',[_vm._v(" "+_vm._s(Math.round( Number(_vm.RouteStopsCumulativeDistanceByName(item)) ).toLocaleString())+" ")]):_vm._e()]),_c('td',[(Number(_vm.RouteStopsFuelStopsByName(item)))?_c('div',[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-gas-station")]),_vm._v(" x "+_vm._s(Number(_vm.RouteStopsFuelStopsByName(item)))+" ")]}}],null,true)},[_c('span',[_vm._v("Number of fuel stops between this bonus and the previous bonus")])])],1):_vm._e(),(item.restbonusandtime !== null)?_c('div',[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-bed")]),_vm._v(" x "+_vm._s(item.restbonusandtime.split(',').length)+" ")]}}],null,true)},[_c('span',[_vm._v("Number of rest stops between this bonus and the previous bonus")])])],1):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatTravelTime(item.map.traveltime)))]),_c('td',[_vm._v(" "+_vm._s(Number( item.order > 1 && _vm.RouteStopsSortedByOrder[Number(item.order - 1)] ? _vm.RouteStopsSortedByOrder[Number(item.order - 1)] .dwelltime : 0 ) + Number(_vm.RouteStopsFuelStopsByName(item)) * _vm.Preferences.routing.fuel_stopduration + Number(_vm.RouteStopsRestDurationByName(item)))+" minutes ")]),_c('td',[(!item.validateTimerestrictions().valid)?_c('div',{staticClass:"red--text"},[_vm._v(" "+_vm._s(item.getFormattedtime(_vm.toggleSwitchDateDay, 'arrival'))+" "),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"red"}},on),[_vm._v("mdi-clock-alert-outline")])]}}],null,true)},[_c('span',[_vm._v("This bonus has time restrictions."),_c('br'),_vm._v(" "+_vm._s(_vm.BonusLocationsByNameGetter(item.name).timerestrictions ? _vm.BonusLocationsByNameGetter(item.name) .timerestrictions : 'Daylight only'))])])],1):_vm._e(),(item.validateTimerestrictions().valid)?_c('div',[_vm._v(" "+_vm._s(item.getFormattedtime(_vm.toggleSwitchDateDay, 'arrival'))+" ")]):_vm._e()]),_c('td',[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mx-1",attrs:{"small":""},on:{"click":function($event){return _vm.displayWeatherDialog(item)}}},on),[_vm._v("mdi-weather-cloudy")])]}}],null,true)},[_c('span',[_vm._v("Weather forecast")])]),(item.name !== 'START' && item.name !== 'END')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mx-1",attrs:{"small":""},on:{"click":function($event){return _vm.addRestStop(item)}}},on),[_vm._v("mdi-chat-sleep")])]}}],null,true)},[_c('span',[_vm._v("Manage rest bonuses")])]):_vm._e(),(item.name !== 'START' && item.name !== 'END')?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mx-1",attrs:{"small":""},on:{"click":function($event){return _vm.askDeleteRouteStop(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete route stop")])]):_vm._e()],1)])}),0)],1):_vm._e()],1)],1),_c(VCard,{staticClass:"mt-2",attrs:{"elevation":"2"}},[_c(VAlert,{attrs:{"outlined":"","dense":"","type":_vm.warningText.type,"prominent":"","border":"left"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.warningText.message)}})])],1),_c(VCard,{staticClass:"mt-2",attrs:{"elevation":"2"}},[_c(VCardSubtitle,{staticClass:"text-h6"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"2"}},[_vm._v("Route overview")]),_c(VCol,{attrs:{"cols":10 - 4 * _vm.showShortestRoute,"align":"right"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,{staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){_vm.visibleMarkerS = !_vm.visibleMarkerS}}},[_c(VIcon,_vm._g({attrs:{"color":_vm.visibleMarkerS === true ? 'black' : 'grey lighten-2'}},on),[_vm._v("mdi-size-s")])],1)]}}])},[_c('span',[_vm._v("Toggle small bonuses")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,{staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){_vm.visibleMarkerM = !_vm.visibleMarkerM}}},[_c(VIcon,_vm._g({attrs:{"color":_vm.visibleMarkerM === true ? 'black' : 'grey lighten-2'}},on),[_vm._v("mdi-size-m")])],1)]}}])},[_c('span',[_vm._v("Toggle medium bonuses")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,{staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){_vm.visibleMarkerL = !_vm.visibleMarkerL}}},[_c(VIcon,_vm._g({attrs:{"color":_vm.visibleMarkerL === true ? 'black' : 'grey lighten-2'}},on),[_vm._v("mdi-size-l")])],1)]}}])},[_c('span',[_vm._v("Toggle large bonuses")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,{staticClass:"ml-1",attrs:{"icon":""},on:{"click":function($event){_vm.visibleMarkerXL = !_vm.visibleMarkerXL}}},[_c(VIcon,_vm._g({attrs:{"color":_vm.visibleMarkerXL === true ? 'black' : 'grey lighten-2'}},on),[_vm._v("mdi-size-xl")])],1)]}}])},[_c('span',[_vm._v("Toggle extra large bonuses")])])],1),(_vm.showShortestRoute)?_c(VCol,{attrs:{"cols":"4"}},[(_vm.showShortestRoute)?_c('span',{staticClass:"ml-2"},[_vm._v("Shortest route")]):_vm._e(),(_vm.showShortestRoute)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showShortestRoute = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e()],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":12 - 4 * _vm.showShortestRoute}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"options":_vm.mapOptions,"center":_vm.mapCenter,"zoom":_vm.mapZoom}},[_c('GmapInfoWindow',{attrs:{"position":_vm.infoWinPosition,"opened":_vm.infoWinOpen,"options":{
                pixelOffset: { width: 0, height: 0 },
                content: _vm.infoWinText
              }},on:{"closeclick":function($event){return _vm.hideRouteStopDetails()}}}),_vm._l((_vm.mapMarkersRouteStops),function(m){return _c('GmapMarker',{key:m.name,attrs:{"position":m.position,"clickable":true,"draggable":false,"icon":m.icon,"label":m.label,"visible":true},on:{"click":function($event){return _vm.displayRouteStopDetails({ name: m.label.text })}}})}),_vm._l((_vm.mapMarkersBonusS),function(m){return _c('GmapMarker',{key:m.name,attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":m.icon,"label":m.label,"visible":_vm.visibleMarkerS}})}),_vm._l((_vm.mapMarkersBonusM),function(m){return _c('GmapMarker',{key:m.name,attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":m.icon,"label":m.label,"visible":_vm.visibleMarkerM}})}),_vm._l((_vm.mapMarkersBonusL),function(m){return _c('GmapMarker',{key:m.name,attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":m.icon,"label":m.label,"visible":_vm.visibleMarkerL}})}),_vm._l((_vm.mapMarkersBonusXL),function(m){return _c('GmapMarker',{key:m.name,attrs:{"position":m.position,"clickable":false,"draggable":false,"icon":m.icon,"label":m.label,"visible":_vm.visibleMarkerXL}})}),(_vm.mapPath.length > 0)?_c('GmapPolyline',{attrs:{"path":_vm.mapPath,"options":{ strokeColor: '#000000', strokeWeight: '2' }}}):_vm._e()],2)],1),(_vm.showShortestRoute)?_c(VCol,{attrs:{"cols":"4"}},[_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.shortestRouteStops.distance ? Number(_vm.shortestRouteStops.distance).toLocaleString() : '')+" miles in "+_vm._s(_vm.shortestRouteStops.duration ? _vm.formatTravelTime(Number(_vm.shortestRouteStops.duration)) : '')+" moving time")]),_c('br'),_c('span',{staticClass:"caption"},[_vm._v("This route does not take time restrictions into consideration!")]),_c(VVirtualScroll,{attrs:{"items":_vm.shortestRouteStops.route,"height":"450","item-height":"35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c(VListItem,{key:item},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":item === 'START'
                        ? 'green'
                        : item === 'END'
                        ? 'red'
                        : 'blue'}},[_vm._v("mdi-flag")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item)+" ")])],1)],1)]}}],null,false,3181154222)})],1):_vm._e()],1)],1)],1),(_vm.BonusLocationsAttainedCombosGetter.length > 0)?_c(VCard,{staticClass:"mt-2",attrs:{"elevation":"2"}},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Combo bonuses")]),_c(VCardText,[(_vm.BonusLocationsAttainedCombosGetter.length > 0)?_c(VDataTable,{staticClass:"mt-4",attrs:{"dense":"","show-expand":"","single-expand":"","items":_vm.BonusLocationsAttainedCombosGetter,"item-key":"name","disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"width":"5%"}}),_c('td',{attrs:{"width":"5%"}},[(item.name)?_c(VIcon,{attrs:{"color":item.getSymbolcolor()}},[_vm._v(_vm._s(item.getMaterialicon()))]):_vm._e()],1),_c('td',{attrs:{"width":"12%"}},[_vm._v(_vm._s(item.name))]),_c('td',{attrs:{"width":"19%"}},[_vm._v(_vm._s(Number(item.points).toLocaleString()))]),_c('td',[_vm._v(_vm._s(item.combostops.split(',').join(', ')))]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.BonusLocationsAttainedCombosGetter.length)+" combos")]),_c('th',[_vm._v(" "+_vm._s(_vm.calculateAttainedCombosPoints().toLocaleString())+" points ")]),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th')])]},proxy:true}],null,false,358202912)}):_vm._e()],1)],1):_vm._e(),(_vm.BonusNonRidingExludingRest.length > 0)?_c(VCard,{staticClass:"mt-2",attrs:{"elevation":"2"}},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Non-mappable bonuses")]),_c(VCardText,[(_vm.BonusNonRidingExludingRest.length > 0)?_c(VDataTable,{staticClass:"mt-4",attrs:{"dense":"","items":_vm.BonusNonRidingExludingRest,"item-key":"name","disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"width":"5%"}}),_c('td',{attrs:{"width":"5%"}},[(item.name)?_c(VIcon,{attrs:{"small":"","color":item.getSymbolcolor()}},[_vm._v(_vm._s(item.getMaterialicon()))]):_vm._e()],1),_c('td',{attrs:{"width":"12%"}},[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(Number(item.points).toLocaleString()))]),_c('td',[_vm._v(_vm._s(item.comment))]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.BonusNonRidingExludingRest.length)+" bonuses")]),_c('th',[_vm._v(" "+_vm._s(_vm.calculateAttainedNonRidingPoints().toLocaleString())+" points ")]),_c('th')])]},proxy:true}],null,false,668170214)}):_vm._e()],1)],1):_vm._e(),_c(VCard,{staticClass:"mt-2",attrs:{"elevation":"2"}},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Route summary")]),_c(VCardText,_vm._l((_vm.routeSummary),function(item){return _c(VRow,{key:item.actualsName,attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"ml-15 mr-5"},on),[_vm._v(_vm._s(item.actualsIcon))]),_c('span',[_vm._v(" "+_vm._s(item.actualsName)+": "+_vm._s(item.actualsValue))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.actualsHover))])])],1),_c(VCol,{attrs:{"cols":"4"}},[(Number(item.attainment) > 0)?_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v(_vm._s(item.targetIcon))]),_vm._v(_vm._s(item.targetName)+": "+_vm._s(item.targetValue.toLocaleString()))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.targetHover))])]):_vm._e()],1),_c(VCol,{attrs:{"cols":"4"}},[(Number(item.attainment) > 0)?_c(VProgressLinear,{attrs:{"value":item.attainment,"color":"blue","height":"20"}},[_c('strong',[_vm._v(_vm._s(item.attainment)+"%")])]):_vm._e()],1)],1)}),1)],1),_c('dialogAddRestStop',{on:{"stopUpdated":function($event){return _vm.calculateSegment(_vm.dialogAddRestStop.item.order)}},model:{value:(_vm.dialogAddRestStop),callback:function ($$v) {_vm.dialogAddRestStop=$$v},expression:"dialogAddRestStop"}}),_c('dialogWeather',{attrs:{"weatherLocation":_vm.weatherLocation},model:{value:(_vm.showDialogWeather),callback:function ($$v) {_vm.showDialogWeather=$$v},expression:"showDialogWeather"}}),_c('dialogDelete',{attrs:{"objectid":_vm.objectidToBeDeleted},on:{"delete":_vm.dialogDeleteAction},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}}),_c('dialogSelectRoute',{on:{"select":_vm.dialogSelectRouteAction},model:{value:(_vm.showSelectRouteDialog),callback:function ($$v) {_vm.showSelectRouteDialog=$$v},expression:"showSelectRouteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }