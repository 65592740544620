/**
 * @module routeOptimization
 */
import axios from 'axios'

/**
 * @typedef {Object} routeOptimzationResult
 * @property {Number} distance The total distance, in miles, of the most optimal route
 * @property {Number} duration The total time, in minutes, for the most optimal route
 * @property {Array<String>} route An array of ordered route stop short names (as defined in the rally book) in string format
 */

/**
 * @description This function use the Open Route Service Optimization API, via an Axios query, to calculate the optimal route based on the latitude and longitude of all route stops in the provided array. This function does not consider any time restrictions.
 * @function routeOptimization
 * @memberof module:routeOptimization
 * @param {Array<RouteStops>} _routestops Array of unordered RouteStop objects
 * @returns {routeOptimzationResult} Returns an object that contain the time, distance and optimal route order
 * @todo The function should maybe take time restrictions into consideration, the ORS API supports it
 */
export async function routeOptimization(_routestops) {
  let locationStart =
    this.$store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
      'START'
    )
  let locationEnd =
    this.$store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
      'END'
    )
  // making sure that START is always the first stop and END is always the last stop in the optimized route
  let vehicles = [
    {
      id: 1,
      profile: 'driving-car',
      start: [Number(locationStart.longitude), Number(locationStart.latitude)], //lon, lat of START
      end: [Number(locationEnd.longitude), Number(locationEnd.latitude)] //lon lat of START
    }
  ]
  // iterate over the undordered list of route stops and add each long/lat with a 'service' equal to the bonus stop duration time as configured in user preferences
  let jobs = []
  for (let index in _routestops) {
    if (
      _routestops[index].name !== 'START' &&
      _routestops[index].name !== 'END'
    ) {
      jobs.push({
        id: Number(index),
        service:
          Number(
            this.$store.state.modulePreferences.Preferences.routing
              .bonus_stopduration
          ) * 60,
        location: [
          Number(
            this.$store.getters[
              'moduleBonusLocations/BonusLocationsByNameGetter'
            ](_routestops[index].name).longitude
          ),
          Number(
            this.$store.getters[
              'moduleBonusLocations/BonusLocationsByNameGetter'
            ](_routestops[index].name).latitude
          )
        ]
      })
    }
  }
  const axiosheaders = {
    'Content-Type': 'application/json; charset=utf-8',
    Accept:
      'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
    Authorization:
      this.$store.state.modulePreferences.Preferences.secrets.ORS_API_KEY
  }
  let axiosresult = null
  axiosresult = await axios
    .post(
      'https://api.openrouteservice.org/optimization',
      {
        jobs: jobs,
        vehicles: vehicles,
        options: { g: true }
      },
      {
        headers: axiosheaders
      }
    )
    .catch(function (error) {
      if (error.response) {
        console.error(error.response.data.error.message)
      } else if (error.request) {
        console.error(error.request)
      } else {
        console.error('Error', error.message)
      }
      console.error(error.config)
    })
  // start building the output object, the ordered optimized list of route stops first
  let optimizedRoute = ['START']
  for (let index in axiosresult.data.routes[0].steps) {
    if (_routestops[axiosresult.data.routes[0].steps[index].id])
      optimizedRoute.push(
        _routestops[axiosresult.data.routes[0].steps[index].id].name
      )
  }
  optimizedRoute.push('END')
  let result = {
    distance: Math.floor(Number(axiosresult.data.routes[0].distance) / 1609),
    duration: Number(axiosresult.data.routes[0].duration) / 3600,
    route: optimizedRoute
  }
  return result
}
