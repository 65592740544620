<template>
  <v-dialog
    v-model="value.showAddRestStopDialog"
    max-width="1000px"
    @click:outside="buttonClicked('cancel')"
  >
    <v-card elevation="2">
      <v-form ref="formRestStop" v-model="formRestStopValidation">
        <v-toolbar flat color="blue" dark>
          <v-toolbar-title>Rest stops</v-toolbar-title> <v-spacer />
          <v-btn color="pink" @click="addRestStop('NOPOINTS')"
            >Add rest stop</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="restStopAutoSelect"
                tabindex="1"
                clearable
                :items="RestBonus"
                item-value="name"
                color="blue darken-4"
                dense
                item-text="name"
                label="Rest stop"
                no-data-text="Either all rally rest bonuses have been used in your route or you didn't configure any in the rally setup"
                :rules="[(v) => !!v || 'Item is required']"
                @change="addRestStop(restStopAutoSelect)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-data-table
                :headers="tableHeaderRestStops"
                :items="RestStops"
                :sort-by="['planduration']"
                disable-pagination
                hide-default-footer
                dense
                @click:row="editRestStop"
              >
                <template v-slot:item.planduration="{ item }">
                  {{
                    item.planduration > 0
                      ? Math.floor(item.planduration / 60)
                      : 0
                  }}
                  hours and
                  {{ item.planduration > 0 ? item.planduration % 60 : 0 }}
                  minutes
                </template>
                <template v-slot:item.points="{ item }">
                  {{ Number(item.points).toLocaleString() }}
                </template>
                <template v-slot:item.window="{ item }">
                  <div v-if="!RestBonusWindow(item)" class="red--text">
                    {{ item.window }}
                    <v-icon small color="red">mdi-alarm-note-off</v-icon>
                  </div>
                  <div v-if="RestBonusWindow(item)">
                    {{ item.window }}
                  </div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn small icon @click="deleteRestStop(item)"
                    ><v-icon small>mdi-delete</v-icon></v-btn
                  >
                  <v-btn small icon @click="editRestStop(item)"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <template v-slot:body.append
                  ><tr>
                    <th></th>
                    <th>
                      {{
                        RestStopsTotalDuration > 0
                          ? Math.floor(RestStopsTotalDuration / 60)
                          : 0
                      }}
                      hours and
                      {{
                        RestStopsTotalDuration > 0
                          ? RestStopsTotalDuration % 60
                          : 0
                      }}
                      minutes
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{ RestStopsTotalPoints.toLocaleString() }} points</th>
                    <th></th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="showUpdateDialog">
          <v-row>
            <v-col cols="8">
              <v-slider
                v-model="sliderRestStopDuration"
                class="ml-4 mt-4"
                :min="15"
                :max="720"
                step="5"
                thumb-label="always"
                thumb-size="30"
                dense
                label="Rest stop duration"
              >
              </v-slider></v-col
            ><v-col cols="1"> </v-col
            ><v-col cols="3"
              ><v-btn @click="updatedPlanDuration"
                >Update rest duration</v-btn
              ></v-col
            >
          </v-row>
          <v-row dense>
            <v-col cols="2"></v-col>
            <v-col cols="4"
              ><div class="mt-n9 grey--text">
                {{ Math.floor(sliderRestStopDuration / 60) }} hours and
                {{ sliderRestStopDuration % 60 }} minutes
              </div></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            tabindex="8"
            text
            color="grey"
            class="mb-5"
            elevation="0"
            @click="buttonClicked('cancel')"
            >Cancel</v-btn
          >
          <v-btn
            tabindex="7"
            :loading="loadingSaveButton"
            color="green"
            class="mr-5 mb-5 white--text"
            elevation="0"
            @click="buttonClicked('submit')"
            >Save rest stops</v-btn
          >
        </v-card-actions>
      </v-form></v-card
    >
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import { DateTime } from 'luxon'
export default {
  name: 'ShowAddRestStop',
  props: {
    value: { required: true, type: Object }
  },
  data() {
    const data = {
      stopPendingUpdate: null,
      loadingSaveButton: false,
      restStopAutoSelect: null,
      showUpdateDialog: false,
      formRestStopValidation: false,
      restStopHasChanged: false,
      sliderRestStopDuration: 0,
      restStops: [],
      tableHeaderRestStops: [
        { text: 'Name', sortable: false, value: 'name' },
        { text: 'Planned', sortable: false, value: 'planduration' },
        { text: 'Minimum', sortable: false, value: 'minimumduration' },
        { text: 'Maximum', sortable: false, value: 'maximumduration' },
        {
          text: 'Points per minute',
          sortable: false,
          value: 'pointsperminute'
        },
        { text: 'Points', sortable: false, value: 'points' },
        { text: 'Window', sortable: false, value: 'window', width: '20%' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      RouteStops: (state) => state.moduleRouteStops.RouteStops,
      Rallies: (state) => state.moduleRallies.Rallies
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsByNameGetter',
      'BonusLocationsByCategoryGetter'
    ]),
    ...mapGetters('moduleRouteStops', ['RouteStopsSortedByOrder']),
    RestBonus() {
      let restBonus = null
      restBonus = this.BonusLocationsByCategoryGetter('N').filter(function (
        bonus
      ) {
        return bonus.symbol === 'rest'
      })
      if (restBonus === null) return null
      for (let index in restBonus) {
        for (let index2 in this.RouteStops) {
          if (this.RouteStops[index2].restbonusandtime) {
            if (
              this.RouteStops[index2].restbonusandtime.includes(
                restBonus[index].name
              )
            ) {
              restBonus.splice(restBonus.indexOf(restBonus[index]), 1)
              if (restBonus.length === 0) {
                restBonus = null
                break
              }
            }
          }
        }
      }
      return restBonus
    },
    RestStopsTotalDuration() {
      if (this.value.item.restbonusandtime === null) return 0
      let work = this.value.item.restbonusandtime.split(',')
      let total = 0
      for (let index in work) {
        total = total + Number(work[index].split('-')[1])
      }
      return total
    },
    RestStopsTotalPoints() {
      let total = 0
      for (let index in this.RestStops) {
        total = total + Number(this.RestStops[index].points)
      }
      return total
    },
    RestStops() {
      if (!this.value.item || !this.value.item.restbonusandtime) return []
      let restStops = []
      let work = this.value.item.restbonusandtime.split(',')
      for (let index in work) {
        if (this.BonusLocationsByNameGetter(work[index].split('-')[0])) {
          let restBonus = this.BonusLocationsByNameGetter(
            work[index].split('-')[0]
          )

          let restStopPoints = 0
          let pointsPerMinute = Number(
            restBonus.points / restBonus.restmaxduration
          )
          let planDuration = work[index].split('-')[1]
          restStopPoints = Math.round(
            Number(pointsPerMinute) * Number(planDuration)
          )
          if (Number(planDuration) < Number(restBonus.restminduration)) {
            restStopPoints = 0
          }
          if (Number(planDuration) > Number(restBonus.restmaxduration)) {
            restStopPoints = Math.round(
              Number(restBonus.restmaxduration) * Number(pointsPerMinute)
            )
          }
          restStops.push({
            name: work[index].split('-')[0],
            planduration: work[index].split('-')[1],
            minimumduration: restBonus.restminduration,
            maximumduration: restBonus.restmaxduration,
            pointsperminute:
              restBonus.restmaxduration > 0
                ? Number(restBonus.points / restBonus.restmaxduration).toFixed(
                    1
                  )
                : 0,
            points: restStopPoints,
            window: restBonus.timerestrictions
          })
        } else {
          restStops.push({
            name: work[index].split('-')[0],
            planduration: work[index].split('-')[1],
            minimumduration: 'n/a',
            maximumduration: 'n/a',
            pointsperminute: 'n/a',
            points: 0,
            window: 'n/a'
          })
        }
      }
      return restStops
    },
    activeRally() {
      const item = this.Rallies.find(
        (rally) => rally.rallyid === this.UserProfile.activerallyid
      )
      return item
    }
  },
  methods: {
    ...mapActions('moduleRouteStops', ['RouteStopsCreateUpdateAction']),
    async buttonClicked(_button) {
      if (_button === 'submit' && this.restStopHasChanged === true) {
        this.loadingSaveButton = true
        await this.RouteStopsCreateUpdateAction(this.value.item)
        this.$emit('stopUpdated', true)
      }
      this.restStopAutoSelect = null
      this.loadingSaveButton = false
      this.value.showAddRestStopDialog = false
      this.$emit('input', this.value)
    },
    RestBonusWindow(_item) {
      if (_item.window === 'n/a') return true
      let previousStop = JSON.parse(
        JSON.stringify(
          this.RouteStopsSortedByOrder.find(
            (routeStop) => routeStop.order === this.value.item.order - 1
          )
        )
      )
      let nextStop = JSON.parse(
        JSON.stringify(
          this.RouteStopsSortedByOrder.find(
            (routeStop) => routeStop.order === this.value.item.order + 1
          )
        )
      )
      let windowStartTime = _item.window.split(' to ')[0].split(' @ ')
      let windowEndTime = _item.window.split(' to ')[1].split(' @ ')
      let startRestTime = DateTime.fromISO(
        `${windowStartTime[0]}T${windowStartTime[1]}`,
        { zone: this.BonusLocationsByNameGetter(_item.name).timezoneid }
      )
      let endRestTime = DateTime.fromISO(
        `${windowEndTime[0]}T${windowEndTime[1]}`,
        { zone: this.BonusLocationsByNameGetter(_item.name).timezoneid }
      )
      let arrivalTime = DateTime.fromISO(this.value.item.map.arrivaltime, {
        zone: this.value.item.timezoneid
      })
      let departureTime = DateTime.fromISO(previousStop.map.departuretime, {
        zone: previousStop.timezoneid
      })
      let arrivalNextStop = DateTime.fromISO(nextStop.map.departuretime, {
        zone: nextStop.timezoneid
      })
      return (
        (departureTime <= startRestTime && startRestTime <= arrivalTime) ||
        (departureTime >= startRestTime && arrivalTime <= endRestTime) ||
        (departureTime <= endRestTime && endRestTime <= arrivalNextStop)
      )
    },
    addRestStop(_stopname) {
      this.restStopHasChanged = true
      _stopname = `${_stopname}-15`
      if (this.value.item.restbonusandtime === null) {
        this.value.item.restbonusandtime = _stopname
      } else {
        this.value.item.restbonusandtime = `${this.value.item.restbonusandtime},${_stopname}`
      }
    },
    deleteRestStop(_stop) {
      this.restStopHasChanged = true
      this.value.item.restbonusandtime = JSON.parse(
        JSON.stringify(this.value.item.restbonusandtime)
      )
        .replace(`${_stop.name}-${_stop.planduration}`, '')
        .replace(',,', ',')
        .replace(/^,+|,+$/g, '')
      if (this.value.item.restbonusandtime === '')
        this.value.item.restbonusandtime = null
    },
    editRestStop(_stop) {
      this.sliderRestStopDuration = _stop.planduration
      this.showUpdateDialog = true
      this.stopPendingUpdate = _stop
    },
    updatedPlanDuration() {
      this.restStopHasChanged = true
      let oldName = `${this.stopPendingUpdate.name}-${this.stopPendingUpdate.planduration}`
      let newName = `${this.stopPendingUpdate.name}-${this.sliderRestStopDuration}`
      this.value.item.restbonusandtime = JSON.parse(
        JSON.stringify(this.value.item.restbonusandtime)
      )
        .replace(oldName, newName)
        .replace(',,', ',')
        .replace(/^,+|,+$/g, '')
      this.sliderRestStopDuration = 15
      this.stopPendingUpdate = null
      this.showUpdateDialog = false
    }
  }
}
</script>

<style></style>
