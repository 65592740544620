<template>
  <v-dialog v-model="value" max-width="1000px">
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Select route</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6 body-1">
        <v-data-table
          :headers="headers"
          :items="RoutesSortedByTimeGetter"
          :sort-by="['description']"
          class="elevation-1"
          dense
          @click:row="loadRoute"
        >
          <template v-slot:item.modified="{ item }">
            {{ item.getFormattedtime() }}
          </template>
          <template v-slot:item.selected="{ item }">
            <v-icon
              v-if="item.routeid === RoutesGetMostRecentRouteGetter().routeid"
              >mdi-arrow-right-thick</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('input', false)"
          >Dismiss</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: {
    value: { required: true, type: Boolean }
  },
  data() {
    return {
      headers: [
        { text: 'Active', value: 'selected', width: 90 },
        { text: 'Description', value: 'description', width: 200 },
        { text: 'Modified on', value: 'modified' }
      ]
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Routes: (state) => state.moduleRoutes.Routes
    }),
    ...mapGetters('moduleRoutes', [
      'RoutesSortedByTimeGetter',
      'RoutesGetMostRecentRouteGetter'
    ])
  },
  methods: {
    loadRoute(_route) {
      this.$emit('select', _route)
      this.$emit('input', false)
    }
  }
}
</script>
