/**
 * @module RoutePlan
 */
/**
 * @typedef {String} UUIDv4 Random number in [UUID v4 format](https://en.wikipedia.org/wiki/Universally_unique_identifier)
 * @author Patrick Nijsters
 * @global
 */
/**
 * @typedef {String} ISO8601 The [ISO 8601 string representation](https://www.w3.org/TR/NOTE-datetime) of the date and time.
 * This string should **not** be manipulated directly, instead use the Luxon method DateTime.fromISO() to convert the ISO8601 into a [Luxon](https://moment.github.io/luxon/#/) DateTime object which has many methods for manipulation of datetime.\
 * \
 * The format is _YYYY-MM-DD**T**HH:MM:SS:sss**TZD**_ where:google
 * * YYYY is the four-digit year (eg 2021)
 * * MM is the 2-digit zero padded month (eg 08)
 * * DD is the 2-digit zero padded day (eg 20)
 * * T is the demarcation between date and time
 * * HH is the 2-digit 24 hour format zero padded hours (eg 23)
 * * MM is the 2-digit zero padded minutes (eg 01)
 * * SS is the 2-digit zero padded seconds (eg 09)
 * * sss is the 3-digit zero padded micro-seconds (eg 097)
 * * TZD is the 2-digit time zone delimiter which is the time offset from UTC in HH:MM format (eg -05:00)
 * @author Patrick Nijsters
 * @global
 * @example 2021-09-28T11:04:00.000-05:00
 */

import { mapState, mapGetters, mapActions } from 'vuex'
import dialogAddRestStop from '@/components/dialogs/dialogAddRestStop.vue'
import dialogWeather from '@/components/dialogs/dialogWeather.vue'
import * as parseTimeRestrictionsFunction from '@/components/parseTimeRestrictions.js'
import * as routeOptimizationFunction from '@/components/routeOptimization.js'
import { gmapApi } from 'vue2-google-maps'
import { DateTime } from 'luxon'
import { RouteStopsPrototype } from '@/components/prototypes/routestops'
import { RoutesPrototype } from '@/components/prototypes/routes'
import { LegPlanPrototype } from '@/components/prototypes/legplan'
import dialogDelete from '@/components/dialogs/dialogDelete'
import dialogSelectRoute from '@/components/dialogs/dialogSelectRoute'
import draggable from 'vuedraggable'

/**
 * @description Vue RoutePlan module providing the application page for the route planning
 */
export default {
  name: 'RoutePlan',
  components: {
    dialogAddRestStop,
    dialogWeather,
    dialogDelete,
    dialogSelectRoute,
    draggable
  },
  data() {
    const data = {
      objectidToBeDeleted: '',
      showDeleteDialog: false,
      infoWinOpen: false,
      infoWinPosition: { lat: 29.53675341061847, lng: -95.57993684576728 },
      infoWinText: '',
      detailsRouteStop: {},
      shortestRouteStops: [],
      weatherLocation: {},
      showDialogWeather: false,
      showSelectRouteDialog: false,
      showShortestRoute: false,
      showLoadingDialog: false,
      directionsApiCallCounter: 0,
      directionsApiTimer: Date.now(),
      mapCenter: { lat: 29.53675341061847, lng: -95.57993684576728 },
      mapZoom: 6,
      mapOptions: {
        controlSize: 20,
        clickableIcons: false,
        streetViewControl: false,
        fullscreenControl: false,
        panControlOptions: false,
        gestureHandling: 'greedy',
        mapTypeId: 'roadmap',
        maxZoom: '18',
        minZoom: '4'
      },
      mapMarkersRouteStops: [],
      mapMarkersBonusS: [],
      mapMarkersBonusM: [],
      mapMarkersBonusL: [],
      mapMarkersBonusXL: [],
      visibleMarkerS: false,
      visibleMarkerM: false,
      visibleMarkerL: false,
      visibleMarkerXL: false,
      mapPath: [],
      dialogAddRestStop: {
        showAddRestStopDialog: false,
        item: null
      },
      showLoadingOverlay: false,
      showApiThrottleOverlay: false,
      toggleSwitchCumulativeDistance: null,
      toggleSwitchDateDay: 'date',
      bonusStopAutoSelect: null,
      attainedCombos: [],
      tableHeaders: [
        { sortable: false, value: 'data-table-expand', width: '2%' },
        { text: '', sortable: false, value: 'icon', width: '2%' },
        { text: 'Name', sortable: false, value: 'name', width: '12%' },
        { text: 'Points', sortable: false, value: 'points', width: '8%' },
        { text: 'Distance', sortable: false, value: 'distance', width: '10%' },
        { text: 'Stops', sortable: false, value: 'fuelstops' },
        { text: 'Moving time', sortable: false, value: 'movingtime' },
        { text: 'Stopped time', sortable: false, value: 'stoppedtime' },
        { text: 'Arrival time', sortable: false, value: 'arrivaltime' },
        { text: 'Actions', sortable: false, value: 'actions', width: '15%' }
      ],
      tableHeadersCombos: [
        { value: 'data-table-expand', width: '2%' },
        { text: '', sortable: false, value: 'icon', width: '2%' },
        { text: '', sortable: false, value: 'name', width: '12%' },
        { text: '', sortable: false, value: 'points', width: '10%' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' }
      ],
      tableHeadersNonRiding: [
        { value: 'data-table-expand', width: '2%' },
        { text: '', sortable: false, value: 'icon', width: '2%' },
        { text: '', sortable: false, value: 'name', width: '12%' },
        { text: '', sortable: false, value: 'points', width: '10%' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' },
        { text: '', value: '' }
      ]
    }
    return {
      ...data,
      ...parseTimeRestrictionsFunction,
      ...routeOptimizationFunction
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      Rallies: (state) => state.moduleRallies.Rallies,
      RouteStops: (state) => state.moduleRouteStops.RouteStops,
      LegPlan: (state) => state.moduleLegPlan.LegPlan,
      RouteServiceCache: (state) => state.moduleRouteService.RouteServiceCache,
      Routes: (state) => state.moduleRoutes.Routes
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsRidingOnlyGetter',
      'BonusLocationsByNameGetter',
      'BonusLocationsNonRidingOnlyGetter'
    ]),
    ...mapGetters('moduleRouteStops', [
      'RouteStopsSortedByOrder',
      'RouteStopsTotalDistance',
      'RouteStopsTotalTime',
      'RouteStopsTotalStops',
      'RouteStopsTotalDwellTime',
      'RouteStopsTotalPoints',
      'RouteStopsByName',
      'RouteStopsRestPointsByName',
      'RouteStopsFuelStopsByName',
      'RouteStopsRestDurationByName',
      'RouteStopsTotalStoppedTime',
      'RouteStopsCumulativeDistanceByName',
      'RouteStopsTotalFuelStops',
      'RouteStopsTotalRestStops',
      'RouteStopsTotalRestTime',
      'RouteStopsSortedByOrderIndexByName',
      'RouteStopsByIdGetter'
    ]),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsAttainedCombosGetter',
      'BonusLocationsCombosConstituentsGetter'
    ]),
    ...mapGetters('moduleRallies', ['RalliesGetRallyByIdGetter']),
    ...mapGetters('moduleRoutes', [
      'RoutesGetRouteByIdGetter',
      'RoutesGetMostRecentRouteGetter',
      'RoutesSortedByTimeGetter'
    ]),
    google: gmapApi,
    draggableRouteStops: {
      get() {
        return this.RouteStopsSortedByOrder
      },
      async set(updatedroutestops) {
        this.showLoadingOverlay = true
        for (let index in updatedroutestops) {
          // we dont need to update START so let's only start updating from the first stop after START
          if (
            Number(index) >= 1 &&
            Number(index) < updatedroutestops.length - 1
          ) {
            updatedroutestops[index].order = Number(index) + 1
            await this.RouteStopsCreateUpdateAction(updatedroutestops[index])
          }
        }
        for (let index in updatedroutestops) {
          if (Number(index) >= 1)
            await this.calculateRouteSegment(
              updatedroutestops[index - 1],
              updatedroutestops[index]
            )
        }
        this.updateGoogleMap()
        this.showLoadingOverlay = false
      }
    },
    warningText() {
      let end = this.RouteStopsByName('END')
      if (!end.name) return { message: '', type: 'success' }
      return {
        message: end.validateTimerestrictions().message,
        type: end.validateTimerestrictions().type
      }
    },
    routeSummary() {
      let routesummary = []
      if (!this.LegPlan[0]) return routesummary
      routesummary[0] = {
        actualsIcon: 'mdi-counter',
        actualsName: 'Total route points',
        actualsHover: 'Total number of points gathered for this route',
        actualsValue:
          Number(this.RouteStopsTotalPoints) +
          Number(this.calculateAttainedCombosPoints()) +
          Number(this.calculateAttainedNonRidingPoints()),
        targetIcon: 'mdi-target',
        targetName: 'Target route points',
        targetHover: 'Target number of points for this route',
        targetValue: Math.round(this.LegPlan[0].targetbonuspoints),
        attainment: Math.round(
          ((Number(this.RouteStopsTotalPoints) +
            Number(this.calculateAttainedCombosPoints()) +
            Number(this.calculateAttainedNonRidingPoints())) /
            Number(this.LegPlan[0].targetbonuspoints)) *
            100
        )
      }
      routesummary[1] = {
        actualsIcon: 'mdi-map-marker-distance',
        actualsName: 'Total miles',
        actualsHover: 'Total number of miles to go for this route',
        actualsValue: Math.round(this.RouteStopsTotalDistance),
        targetIcon: 'mdi-target',
        targetName: 'Target miles',
        targetHover: 'Total target number of miles for this route',
        targetValue: this.LegPlan[0].planningmiles
          ? this.LegPlan[0].planningmiles
          : '',
        attainment: Math.round(
          (Number(this.RouteStopsTotalDistance) /
            Number(this.LegPlan[0].planningmiles)) *
            100
        )
      }
      routesummary[2] = {
        actualsIcon: 'mdi-map-clock',
        actualsName: 'Total moving time',
        actualsHover: 'Total number of hours in the saddle for this route',
        actualsValue: this.formatTravelTime(Number(this.RouteStopsTotalTime)),
        targetIcon: 'mdi-target',
        targetName: 'Target moving time',
        targetHover:
          'Total target number of hours in the saddle for this route',
        targetValue: this.formatTravelTime(this.LegPlan[0].totalmovingtime),
        attainment: Math.round(
          (Number(this.RouteStopsTotalTime) / this.LegPlan[0].totalmovingtime) *
            100
        )
      }
      routesummary[3] = {
        actualsIcon: 'mdi-bed',
        actualsName: 'Total rest time',
        actualsHover: 'Total number of hours of rest for this route',
        actualsValue: this.formatTravelTime(
          Number(this.RouteStopsTotalRestTime)
        ),
        targetIcon: 'mdi-target',
        targetName: 'Target rest time',
        targetHover: 'Total target number of hours of rest for this route',
        targetValue: this.formatTravelTime(this.LegPlan[0].totalreststoptime),
        attainment: Math.round(
          (Number(this.RouteStopsTotalRestTime) /
            this.LegPlan[0].totalreststoptime) *
            100
        )
      }
      routesummary[4] = {
        actualsIcon: 'mdi-map-marker',
        actualsName: 'Total bonus stops',
        actualsHover: 'Total number of bonus stops for this route',
        actualsValue: Number(this.RouteStopsSortedByOrder.length - 2),
        targetIcon: 'mdi-target',
        targetName: 'Target bonus stops',
        targetHover: 'Total target number of bonus stops for this route',
        targetValue: this.LegPlan[0].bonusstops
          ? this.LegPlan[0].bonusstops
          : '',
        attainment: Math.round(
          (Number(this.RouteStopsSortedByOrder.length - 2) /
            this.LegPlan[0].bonusstops) *
            100
        )
      }
      routesummary[5] = {
        actualsIcon: 'mdi-gas-station',
        actualsName: 'Total fuel stops',
        actualsHover: 'Total number of fuel stops for this route',
        actualsValue: Number(this.RouteStopsTotalFuelStops).toLocaleString(),
        targetIcon: 'mdi-target',
        targetName: 'Target fuel stops',
        targetHover: 'Total target number of fuel stops for this route',
        targetValue: this.LegPlan[0].fuelstops ? this.LegPlan[0].fuelstops : '',
        attainment: Math.round(
          (Number(this.RouteStopsTotalFuelStops) / this.LegPlan[0].fuelstops) *
            100
        )
      }
      routesummary[6] = {
        actualsIcon: 'mdi-sleep',
        actualsName: 'Total rest stops',
        actualsHover: 'Total number of rest stops for this route',
        actualsValue: Number(this.RouteStopsTotalRestStops),
        targetIcon: 'mdi-target',
        targetName: 'Target rest stops',
        targetHover: 'Total target number of rest stops for this route',
        targetValue: this.LegPlan[0].reststops ? this.LegPlan[0].reststops : '',
        attainment: Math.round(
          (Number(this.RouteStopsTotalRestStops) / this.LegPlan[0].reststops) *
            100
        )
      }
      return routesummary
    },
    filteredBonusLocationsRidingOnly() {
      let plannedStops = []
      let filteredBonusLocationsRidingOnly = []
      for (let index in this.RouteStops) {
        plannedStops.push(this.RouteStops[index].name)
      }
      for (let index in this.BonusLocationsRidingOnlyGetter) {
        if (
          plannedStops.indexOf(
            this.BonusLocationsRidingOnlyGetter[index].name
          ) < 0
        ) {
          filteredBonusLocationsRidingOnly.push(
            this.BonusLocationsRidingOnlyGetter[index]
          )
        }
      }
      return filteredBonusLocationsRidingOnly
    },
    BonusNonRidingExludingRest() {
      let BonusNonRidingExludingRest = []
      for (let index in this.BonusLocationsNonRidingOnlyGetter) {
        if (this.BonusLocationsNonRidingOnlyGetter[index].symbol !== 'rest')
          BonusNonRidingExludingRest.push(
            this.BonusLocationsNonRidingOnlyGetter[index]
          )
      }
      return BonusNonRidingExludingRest
    }
  },
  watch: {
    mapMarkersRouteStops(_markers) {
      this.centerGoogleMap(_markers)
    }
  },
  /**
   * @function created
   * @author Patrick Nijsters
   * @description This function gets called when the page gets created/loaded and performs the following actions:
   * 1. Figure out the UUIDv4 of the active/selected rally
   * 2. Call the loadRoute module which loads the most recently created route for this rally if it exists. If a route doesnt exist yet, create a new route and set it as active
   * @memberof module:RoutePlan
   * @returns {Void}
   */
  created: async function () {
    this.activeRally = await {
      ...this.RalliesGetRallyByIdGetter(this.UserProfile.activerallyid)
    }
    this.loadRoute()
  },
  methods: {
    ...mapActions('moduleRouteStops', [
      'RouteStopsCreateUpdateAction',
      'RouteStopsReadAllAction',
      'RouteStopsDeleteAction',
      'RouteStopsDeleteAllAction',
      'RouteStopsPushAction'
    ]),
    ...mapActions('moduleBonusLocations', ['BonusLocationsCreateUpdateAction']),
    ...mapActions('moduleRallies', ['RalliesCreateUpdateAction']),
    ...mapActions('moduleLegPlan', ['LegPlanUpdateAction']),
    ...mapActions('moduleRouteService', [
      'RouteServiceGetDirectionsAction',
      'RouteServiceClearAction'
    ]),
    ...mapActions('moduleRoutes', [
      'RoutesDeleteAction',
      'RoutesReadAllAction',
      'RoutesClearAction',
      'RoutesCreateUpdateAction'
    ]),
    ...mapActions('moduleUser', ['UserProfileSetActiveRouteAction']),

    async TEST() {
      console.log('TEST routine')
      this.visibleMarkerS = !this.visibleMarkerS
    },

    /**
     * @function loadRoute
     * @author Patrick Nijsters
     * @description This function reads the Vuex state store and determines if exiting routes present and if so loads the most recent worked on route. If not existing routes: call a routine to create a new one. Legplan is loaded and recalculated for existing and newly created route. The routine lastly updates the userprofile with the UUIDv4 of the active/selected route
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async loadRoute() {
      await this.RoutesReadAllAction()
      if (this.RoutesGetMostRecentRouteGetter() === null) {
        //create new route since this is a newly created rally and no route exists yet
        await this.createNewRoute()
      } else {
        //load the most recent already existing route
        this.activeRoute = await { ...this.RoutesGetMostRecentRouteGetter() }
        this.UserProfileSetActiveRouteAction(this.activeRoute.routeid)
        await this.loadExistingRoute()
      }
      // calculate and load the legplan for this leg of the rally
      let legProfileInput = new LegPlanPrototype()
      legProfileInput.calculatePlan(this.activeRally)
      this.LegPlanUpdateAction(legProfileInput)
    },

    /**
     * @function dialogSelectRouteAction
     * @author Patrick Nijsters
     * @description This function is called from the SelectRoute dialog when a new route has been selected to be made active
     * @memberof module:RoutePlan
     * @param {RoutesPrototype} _route
     * @returns {Void}
     */
    async dialogSelectRouteAction(_route) {
      await this.UserProfileSetActiveRouteAction(_route.routeid)
      _route.modified = DateTime.now().toISO()
      await this.RoutesCreateUpdateAction(_route)
      await this.loadExistingRoute()
    },

    /**
     * @description This function ask to confirm the deletion of the currently selected route and if confirmed deletes it
     * @function askDeleteRoute
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async askDeleteRoute() {
      this.objectidToBeDeleted = this.activeRoute.routeid
      this.showDeleteDialog = true
    },

    /**
     * @description This function scaffold a new route including START and END routestops
     * @function createNewRoute
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async createNewRoute() {
      let newroute = new RoutesPrototype()
      newroute.initializeRoute()
      await this.RoutesCreateUpdateAction(newroute)
      await this.UserProfileSetActiveRouteAction(newroute.routeid)

      this.shortestRouteStops = [] // empty the calculated optimal route
      this.showShortestRoute = false // and hide the dialog
      this.mapMarkersRouteStops = [] // reset the map
      this.mapMarkersBonusS = []
      this.mapMarkersBonusM = []
      this.mapMarkersBonusL = []
      this.mapMarkersBonusXL = []
      this.mapPath = [] // and remove the paths
      let start = new RouteStopsPrototype()
      let end = new RouteStopsPrototype()
      await this.RouteStopsDeleteAllAction()
      start.initializeCheckpoint('START')
      end.initializeCheckpoint('END')
      await this.RouteStopsCreateUpdateAction(start)
      await this.RouteStopsCreateUpdateAction(end)
      await this.calculateRouteSegment(start, end)
      this.updateGoogleMap()
    },

    /**
     * @description This function adds a route stop to the current route. The new routestop gets inserted just before the 'END' stop of the rally. The function will then also call a route segment recalculation from the previous to the new stop and from the new stop to the end stop. Lastly the Google map markers and line get updated.
     * @function addRouteStop
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {String} _routeStopName The short name of the route stop as defined in the rally book
     * @returns {Void}
     * @example addRouteStop('BOB')
     */
    async addRouteStop(_routeStopName) {
      this.shortestRouteStops = []
      this.showShortestRoute = false
      //make room in the route plan by increasing value of the order field of the END stop to make room to insert a new stop in front of the END stop
      let routestopEnd = this.RouteStopsByName('END')
      routestopEnd.order++
      await this.RouteStopsCreateUpdateAction(routestopEnd)

      // scaffold the new stop based on the selected Bonus Location
      let newStop = new RouteStopsPrototype()
      newStop.name = _routeStopName
      newStop.initializeStop()
      await this.RouteStopsCreateUpdateAction(newStop)

      // recalculate the segment from the previous to the new stop and from the new stop to the last stop
      let previousStop = this.RouteStopsSortedByOrder.find(
        (routeStop) => routeStop.order === newStop.order - 1
      )
      let nextStop = this.RouteStopsSortedByOrder.find(
        (routeStop) => routeStop.order === newStop.order + 1
      )
      // calculate the segment from the previous stop to the new stop and the segment from the new stop to the next stop
      await this.calculateRouteSegment(previousStop, newStop)
      await this.calculateRouteSegment(newStop, nextStop)

      this.updateGoogleMap()
      // reset the input form and put focus on the autoselect box
      this.$refs.form.reset()
      this.$refs.autoCompleteRiding.focus()
    },

    /**
     * @function loadExistingRoute
     * @author Patrick Nijsters
     * @description Load the selected route by:
     * 1. using a Vuex action to read the RouteStops from the activeRouteID from Firebase database into the Vuex state store
     * 2. calculating the Legplan for the selected route
     * 3. recalculate the arrival and departure time for the START location if the onclockplanningtime parameter has changed in Preferenes
     * 4. refresh the display of the Google map
     * @memberof module:RoutePlan
     * @todo UPDATE THE LATITUDE AND LONGITUDE OF THE START AND END LOCATIONS WHEN THEY CHANGE IN THE RALLY DEFINITION
     * @returns {Void}
     */
    async loadExistingRoute() {
      await this.RouteStopsReadAllAction()
      if (
        this.activeRally.recalculateroute === true &&
        this.RoutesGetMostRecentRouteGetter() != null
      ) {
        // for existing rally, check if we need to recalculate because route parameters have changed. Parameters that could have changed are START and END latitude and longitude plus the onclockplanningtime which is equal to the dwelltime of the START location
        this.showLoadingOverlay = true
        let updatedRouteStop = this.RouteStopsByName('START')
        updatedRouteStop.dwelltime =
          Number(this.LegPlan[0].onclockplanningtime) * 60
        updatedRouteStop.map.departuretime = DateTime.fromISO(
          updatedRouteStop.map.arrivaltime
        )
          .setZone(updatedRouteStop.timezoneid)
          .plus({
            minutes: Number(updatedRouteStop.dwelltime)
          })
        await this.RouteStopsCreateUpdateAction(updatedRouteStop)
        await this.calculateSegment()
        this.activeRally.recalculateroute = false
        await this.RalliesCreateUpdateAction(this.activeRally)
        this.showLoadingOverlay = false
      }
      this.updateGoogleMap()
      this.$refs.autoCompleteRiding.focus()
    },

    /**
     * @function displayRouteStopDetails
     * @description Show an infobox on Google Maps with the routestop details of the selected marker on the map
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {Object} _marker
     * @returns {Void}
     */
    displayRouteStopDetails(_marker) {
      if (this.google === null) return
      const bonuslocation = this.BonusLocationsByNameGetter(_marker.name)
      const routestop = this.RouteStopsByName(bonuslocation.name)

      this.infoWinText = `<h3>${_marker.name}</h3>
      <hr />
      <b>Distance:</b> ${routestop.map.distance} miles</br>
      <b>Value:</b> ${bonuslocation.value}</br>
      <b>Points:</b> ${bonuslocation.points}</br>
      <b>Points per mile:</b> ${routestop.map.pointspermile}<br>
      <br>
      <b>Arrival time:</b> ${this.RouteStopsByName(
        bonuslocation.name
      ).getFormattedtime(this.toggleSwitchDateDay, 'arrival')}<br>
      <b>Departure time:</b> ${this.RouteStopsByName(
        bonuslocation.name
      ).getFormattedtime(this.toggleSwitchDateDay, 'departure')}<br>
      <b>Travel time:</b> ${routestop.map.traveltime} hours<br>
      <b>Dwell time:</b> ${
        Number(routestop.dwelltime) +
        Number(this.RouteStopsFuelStopsByName(routestop)) *
          this.Preferences.routing.fuel_stopduration +
        Number(this.RouteStopsRestDurationByName(routestop))
      } minutes<br>
      <br />
      <b>Time restrictions:</b> ${
        bonuslocation.category === 'D'
          ? `Daylight only (${bonuslocation.sunrise} to
        ${bonuslocation.sunset})`
          : bonuslocation.timerestrictions === null
          ? 'none'
          : bonuslocation.timerestrictions
      }<br>
      <b>Timezone:</b> ${bonuslocation.timezoneLong}
      <hr /><br>
      <i>(close this window to zoom back out)</i>
      `

      this.infoWinPosition = {
        lat: Number(bonuslocation.latitude),
        lng: Number(bonuslocation.longitude)
      }
      this.infoWinOpen = true
    },

    /**
     * @function hideRouteStopDetails
     * @description Close the info window for a marker and zoom back out to show the route overview
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    hideRouteStopDetails() {
      this.infoWinOpen = false
      this.mapZoom = 6
      this.centerGoogleMap(this.mapMarkersRouteStops)
    },

    /**
     * @description Used by vue-draggable to make sure we don't drag the START and END locations into a different routestop order
     * @author Patrick Nijsters
     * @function checkMove
     * @memberof module:RoutePlan
     * @param {Object} _event Vue-draggable event object
     * @returns {Boolean}
     */
    checkMove(_event) {
      return (
        _event.draggedContext.element.name !== 'START' &&
        _event.draggedContext.element.name !== 'END'
      )
    },

    /**
     * @description wrapper function to call the routeOptimization function and show the output of this function next to the Google map.
     * @function shortestRoute
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async shortestRoute() {
      if (this.shortestRouteStops.length === 0)
        this.shortestRouteStops = await this.routeOptimization(this.RouteStops)
      this.showShortestRoute = true
    },

    /**
     * @description This function toggles the display and activation of the dialog dialogWeather.vue. A dialog window will be shown which displays the weather conditions for the provided route stop
     * @function displayWeatherDialog
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {RouteStopsPrototype} _location The RouteStop object indicating the currently selected route stop in a route plan
     * @returns {Void}
     */
    displayWeatherDialog(_location) {
      this.weatherLocation = _location
      this.showDialogWeather = true
    },

    /**
     * @description Determine a bounding area for all route stops inluded in the marker array and center the Google map in such a way that all markers are shown
     * @function centerGoogleMap
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {Array} _markers
     * @returns {Void}
     */
    centerGoogleMap(_markers) {
      if (this.google === null) return
      const bounds = new this.google.maps.LatLngBounds()
      if (_markers.length > 1) {
        for (let m of _markers) {
          bounds.extend(m.position)
        }
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.fitBounds(bounds)
        })
      }
      if (_markers.length === 1) {
        this.mapCenter = {
          lat: Number(this.mapMarkersRouteStops[0].position.lat),
          lng: Number(this.mapMarkersRouteStops[0].position.lng)
        }
        this.mapZoom = 6
      }
    },

    /**
     * @description For every route stop in the RouteStopsSortedByOrder object place a colored flag with a label showing the route stop name on the displayed Google map. Finally connect all these markers with a line in the order of the route plan.
     * @function updateGoogleMap
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async updateGoogleMap() {
      let iconChoice = ''
      let bonusmarker = null
      this.mapPath = []
      this.mapMarkersRouteStops = []
      this.mapMarkersBonusS = []
      this.mapMarkersBonusM = []
      this.mapMarkersBonusL = []
      this.mapMarkersBonusXL = []

      for (let index in this.BonusLocationsRidingOnlyGetter) {
        bonusmarker = {
          position: {
            lat: Number(this.BonusLocationsRidingOnlyGetter[index].latitude),
            lng: Number(this.BonusLocationsRidingOnlyGetter[index].longitude)
          },
          name: `BL${this.BonusLocationsRidingOnlyGetter[index].name}`,
          label: {
            text: this.BonusLocationsRidingOnlyGetter[index].name,
            color: 'black',
            fontSize: '10px'
          },
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            labelOrigin: { x: 12, y: -10 }
          }
        }
        switch (this.BonusLocationsRidingOnlyGetter[index].value) {
          case 'small':
            this.mapMarkersBonusS.push(bonusmarker)
            break
          case 'medium':
            this.mapMarkersBonusM.push(bonusmarker)
            break
          case 'large':
            this.mapMarkersBonusL.push(bonusmarker)
            break
          case 'extra large':
            this.mapMarkersBonusXL.push(bonusmarker)
            break
        }
      }

      for (let index in this.RouteStopsSortedByOrder) {
        let bonuslocation = this.BonusLocationsByNameGetter(
          this.RouteStopsSortedByOrder[index].name
        )
        switch (this.RouteStopsSortedByOrder[index].name) {
          case 'START':
            iconChoice =
              'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
            break
          case 'END':
            iconChoice = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
            break
          default:
            iconChoice = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        }
        this.mapMarkersRouteStops.push({
          position: {
            lat: Number(bonuslocation.latitude),
            lng: Number(bonuslocation.longitude)
          },
          name: `RS${this.BonusLocationsRidingOnlyGetter[index].name}`,
          label: {
            text: this.RouteStopsSortedByOrder[index].name,
            color: 'black',
            fontSize: '10px'
          },
          icon: {
            url: iconChoice,
            labelOrigin: { x: 12, y: -10 }
          }
        })
        let decode = require('@googlemaps/polyline-codec').decode
        if (Number(index) < Number(this.RouteStopsSortedByOrder.length) - 1) {
          let fromBonus = this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[index].name
          )
          let toBonus = this.BonusLocationsByNameGetter(
            this.RouteStopsSortedByOrder[Number(index) + 1].name
          )
          let routeservice = await this.RouteServiceGetDirectionsAction({
            origin: fromBonus,
            destination: toBonus
          })
          let polyline = decode(routeservice.geometry)
          for (let polyindex in polyline) {
            this.mapPath.push({
              lat: polyline[polyindex][0],
              lng: polyline[polyindex][1]
            })
          }
        }
      }
    },

    /**
     * @description Recalculate every route segment from the route stop prior to the selected route stop until the last ('END') routestop
     * @function calculateSegment
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {Number} _startfrom=2 The order of the routestop to start the recalculation at. Minimum value is 2, this starts the recalculation at START
     * @param {Boolean} _invalidate=false Invalidates and clears the route cache using a Vuex action, which forces ORS queries for every route segment
     * @returns {Void}
     */
    async calculateSegment(_startfrom = 2, _invalidate = false) {
      if (_startfrom < 2 || _startfrom > this.RouteStopsTotalStops + 1) return
      if (_invalidate === true) this.RouteServiceClearAction()
      this.showLoadingOverlay = true
      for (
        let counter = _startfrom - 2;
        counter < this.RouteStopsTotalStops + 1;
        counter++
      ) {
        await this.calculateRouteSegment(
          this.RouteStopsSortedByOrder[counter],
          this.RouteStopsSortedByOrder[counter + 1]
        )
      }
      this.showLoadingOverlay = false
    },

    /**
     * @description This function toggles the display and activation of the dialog dialogAddRestStop.vue
     * @function addRestStop
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {RouteStops} _item route stop object identifying the selected route stop in an active route plan
     * @returns {Void}
     */
    addRestStop(_item) {
      this.dialogAddRestStop.item = _item
      this.dialogAddRestStop.showAddRestStopDialog =
        !this.dialogAddRestStop.showAddRestStopDialog
    },

    /**
     * @description This function calculates the total number of points for completed combos in a route
     * @function calculateAttainedCombosPoints
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Number} attainedCombosPoints The calculated total number of attained combo points in the active route
     * @todo This should really move to moduleRouteStops and integrate there somewhere?
     */
    calculateAttainedCombosPoints() {
      if (this.BonusLocationsAttainedCombosGetter.length === 0) return 0

      return this.BonusLocationsAttainedCombosGetter.reduce(function (
        sum,
        current
      ) {
        return sum + Number(current.points)
      },
      0)
    },

    /**
     * @description This function calculates the total number of non-mappable points attained in a route
     * @function calculateAttainedNonRidingPoints
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Number} attainedNonRidingPoints The calculated total number of attained non-mapable bonus points in the active route
     * @todo This should really move to moduleRouteStops and integrate there somewhere?
     */
    calculateAttainedNonRidingPoints() {
      return this.BonusNonRidingExludingRest.reduce(function (sum, current) {
        return sum + Number(current.points)
      }, 0)
    },

    /**
     * @description This function calculates the route between two specified route stops
     * @function calculateRouteSegment
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {RouteStopsPrototype} _fromStop - the start route stop object
     * @param {RouteStopsPrototype} _toStop - the end route stop object
     * @requires luxon
     * @returns {Void}
     */
    async calculateRouteSegment(_fromStop, _toStop) {
      // API throttle check: if we're calling too often, ORS will throw errors, we want to prevent this
      if (this.directionsApiCallCounter >= 20) {
        let deltaTime = (Date.now() - this.directionsApiTimer) / 1000
        // we cannot do more than 20 API calls per 30 seconds, so we show the user a screen halting further user input
        if (deltaTime > 0) {
          this.showApiThrottleOverlay = true
          await new Promise((r) => setTimeout(r, (30 - deltaTime) * 1000))
          this.showApiThrottleOverlay = false
        }
        // clear the API call counter and reset the timer
        this.directionsApiCallCounter = 0
        this.directionsApiTimer = Date.now()
      }
      // check the cache if we already queried ORS for this segment before (and stored it). If not found, increment the API call counter
      let cacheIndexForward = `${_fromStop.name},${_toStop.name}`
      if (
        this.RouteServiceCache.find(
          (cache) => cache.index === cacheIndexForward
        ) === undefined
      )
        this.directionsApiCallCounter = this.directionsApiCallCounter + 1

      let fromBonus = this.BonusLocationsByNameGetter(_fromStop.name)
      let toBonus = this.BonusLocationsByNameGetter(_toStop.name)

      let routeservice = await this.RouteServiceGetDirectionsAction({
        origin: fromBonus,
        destination: toBonus
      })
      _toStop.map.distance = Number.parseFloat(routeservice.distance).toFixed(2)
      _toStop.map.traveltime = Number.parseFloat(routeservice.duration).toFixed(
        2
      )
      _toStop.map.pointspermile = Number.parseFloat(
        toBonus.points / _toStop.map.distance
      ).toFixed(2)
      _toStop.map.arrivaltime = DateTime.fromISO(_fromStop.map.departuretime)
        .setZone(_toStop.timezoneid)
        .plus({
          minutes: Number(Math.round(_toStop.map.traveltime * 60))
        })
      _toStop.map.departuretime = DateTime.fromISO(_toStop.map.arrivaltime)
        .setZone(_toStop.timezoneid)
        .plus({
          minutes: Number(_toStop.dwelltime)
        })
      // we have to temporarily update the Vuex state to be able to calculate the fuelstops. The below action only updates Vuex and does NOT insert a new record into Firebase. The new record is inserted into Firebase once the fueldistance has been calculated
      await this.RouteStopsPushAction(_toStop)
      _toStop.map.arrivaltime = DateTime.fromISO(_fromStop.map.departuretime)
        .setZone(_toStop.timezoneid)
        .plus({
          minutes:
            Number(
              this.RouteStopsFuelStopsByName(_toStop) *
                this.Preferences.routing.fuel_stopduration
            ) +
            Number(Math.round(_toStop.map.traveltime * 60)) +
            Number(this.RouteStopsRestDurationByName(_toStop))
        })
      _toStop.map.departuretime = DateTime.fromISO(_toStop.map.arrivaltime)
        .setZone(_toStop.timezoneid)
        .plus({
          minutes: Number(_toStop.dwelltime)
        })
      await this.RouteStopsCreateUpdateAction(_toStop)
    },

    /**
     * @function askDeleteRouteStop
     * @description Calls the dialogDelete module to present a dialog on screen asking to confirm the deletion of a routestop
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {RouteStopsPrototype} _routestop
     * @returns {Void}
     */
    askDeleteRouteStop(_routestop) {
      this.objectidToBeDeleted = _routestop.stopid
      this.showDeleteDialog = true
    },

    /**
     * @description This function executes the delete action once confirmed by the user
     * 1. for 'routes' it deletes the existing route and then calls the loadRoute routine which loads the most recent remaining route or creates a new one
     * 2. for 'routestops' it deletes the selected routestop and recalculates the route to account for the deletion
     * @function dialogDeleteAction
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {routestop|route} _event
     * @requires luxon
     * @returns {Void}
     */
    async dialogDeleteAction(_event) {
      if (_event === 'route') {
        await this.RoutesDeleteAction(this.activeRoute.routeid)
        await this.loadRoute()
        this.showDeleteDialog = false
      }
      if (_event === 'routestop') {
        const routestop = this.RouteStopsByIdGetter(this.objectidToBeDeleted)
        this.showLoadingOverlay = true
        this.shortestRouteStops = []
        this.showShortestRoute = false
        let previousStop = this.RouteStopsSortedByOrder.find(
          (routeStop) => routeStop.order === routestop.order - 1
        )
        let nextStop = this.RouteStopsSortedByOrder.find(
          (routeStop) => routeStop.order === routestop.order + 1
        )
        let nextStopArrivalTimeBeforeDelete = nextStop.map.arrivaltime
        await this.RouteStopsDeleteAction(routestop)
        await this.renumberRouteStopsOrder()

        // reread the route stops with updated order
        previousStop = this.RouteStopsByName(previousStop.name)
        nextStop = this.RouteStopsByName(nextStop.name)
        await this.calculateRouteSegment(previousStop, nextStop)

        // update arrival and departure times of all routestops following the deleted routestop
        let nextStopArrivalTimeDelta = DateTime.fromISO(
          nextStopArrivalTimeBeforeDelete,
          { setZone: true }
        ).diff(DateTime.fromISO(nextStop.map.arrivaltime, { setZone: true }))
        let nextStopToBeUpdated =
          this.RouteStopsSortedByOrderIndexByName(nextStop.name) + 1
        for (
          let index = nextStopToBeUpdated;
          index < this.RouteStopsSortedByOrder.length;
          index++
        ) {
          let updatedRouteStop = JSON.parse(
            JSON.stringify(this.RouteStopsSortedByOrder[index])
          )
          updatedRouteStop.map.arrivaltime = DateTime.fromISO(
            updatedRouteStop.map.arrivaltime,
            { setZone: true }
          ).minus(Number(nextStopArrivalTimeDelta))
          updatedRouteStop.map.departuretime = DateTime.fromISO(
            updatedRouteStop.map.departuretime,
            { setZone: true }
          ).minus(Number(nextStopArrivalTimeDelta))
          await this.RouteStopsCreateUpdateAction(updatedRouteStop)
        }
        this.updateGoogleMap()
        this.showLoadingOverlay = false
      }
    },

    /**
     * @function renumberRouteStopsOrder
     * @description Renumber the routestops order (typically after a routestop was deleted)
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @returns {Void}
     */
    async renumberRouteStopsOrder() {
      let sortedRouteStops = JSON.parse(JSON.stringify(this.RouteStops))
      sortedRouteStops = sortedRouteStops.sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
      for (let index in sortedRouteStops) {
        sortedRouteStops[index].order = Number(index) + 1
        await this.RouteStopsCreateUpdateAction(sortedRouteStops[index])
      }
    },

    /**
     * @function formatTravelTime
     * @description Format travel time for display
     * @author Patrick Nijsters
     * @memberof module:RoutePlan
     * @param {Float} _hoursFloat
     * @returns {String} Returns a string formatted as 'xx hours and yy min"
     */
    formatTravelTime(_hoursFloat) {
      let hours = Number(Math.floor(_hoursFloat))
      let minutes = Number(Math.round((_hoursFloat - hours) * 60))
      if (hours === 0) {
        return `${minutes} min`
      }
      if (hours === 1) {
        return `${hours} hr ${minutes} min`
      }
      return `${hours} hrs ${minutes} min`
    }
  }
}
